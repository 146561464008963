import $api from '@/helpers/config-axios'

import authHeader from './auth-header'
import store from '@/store'
import {errorHandler} from '@/assets/scripts/scripts'

class OtherService {
  async getRoles() {
    return await $api
      .get('roles', {
        headers: authHeader(),
      })
      .then(r => {
        const newArr = r.data.data.map(item => {
          return {
            id: item.id,
            title: item['display_name'],
          }
        })
        store.commit('other/getRoles', newArr)
        errorHandler(r)
      })
  }

  async getRolesForRegisterUser() {
    return await $api
      .get('roles?to=list', {
        headers: authHeader(),
      })
      .catch(e => {
        errorHandler(e)
      })
  }

  handlerOptions(selectedItems, mutateData) {
    let options = {
      searchFields: 'id:in',
      include: 'status,partner_status,region',
    }

    if (selectedItems.length > 0) {
      options['search'] = 'id:'
    } else {
      store.commit('sidebars/changeRightSidebar', false)
    }

    for (let i = 0; i < selectedItems.length; i++) {
      if (i !== selectedItems.length - 1) {
        options.search += selectedItems[i] + ','
      } else {
        options.search += selectedItems[i]
      }
    }

    if (selectedItems.length > 1) {
      return options
    } else {
      store.commit(mutateData, [])
      return {}
    }
  }

  async checkPassword(data) {
    return await $api.post('password/check', data, {
      headers: authHeader(),
    })
  }

  getSettings() {
    return $api.get('settings', {
      headers: authHeader(),
    })
  }

  getSettingsSystem() {
    return $api.get('settings/system', {
      headers: authHeader(),
    })
  }

  async createSettings(data) {
    return await $api.post('settings', data, {
      headers: authHeader(),
    })
  }

  async updateSettings(data) {
    return await $api.patch('settings', data, {
      headers: authHeader(),
    })
  }

  async getOptions(path, root) {
    return await $api.get(
      `${process.env.VUE_APP_URL.replace('/v1/', '') + path}?${root ? 'node_id=NxOpZowo9GmjKqdR&' : ''}to=list&limit=*`,
      {
        headers: authHeader(),
      }
    )
  }

  async getProducts() {
    return await $api.get(`${process.env.VUE_APP_URL}directory/items?to=list&limit=*`, {
      params: {
        include: 'unit',
      },
      headers: authHeader(),
    })
  }

  async getHighways() {
    return await $api.get(`highways?to=list&limit=*`, {
      headers: authHeader(),
    })
  }

  async getUnits() {
    return await $api.get(`units?to=list&limit=*`, {
      headers: authHeader(),
    })
  }

  async getCommunityStatuses() {
    return await $api.get(`community-statuses?to=list&limit=*`, {
      headers: authHeader(),
    })
  }

  async getHumanPositions() {
    return await $api.get(`human-positions?to=list&limit=*`, {
      headers: authHeader(),
    })
  }

  async getProperties() {
    return await $api.get(`${process.env.VUE_APP_URL}directory/properties?to=list&limit=*`, {
      headers: authHeader(),
    })
  }

  uploadDataInCSV(id) {
    return $api.get(`${process.env.VUE_APP_URL}directory/export-items`, {
      headers: authHeader(),
    })
  }

  async createAmoCrmLead(data) {
    return await $api.post('amo-crm/leads', data)
  }
}

export default new OtherService()
