var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"zem-input-container"},[(_vm.title)?_c('label',[_vm._v(_vm._s(_vm.title))]):_vm._e(),_c('input',{staticClass:"zem-input",class:[
      _vm.inputClass,
      {
        'phone-mask': _vm.mask === 'phone',
        'integer-input': _vm.integer,
        'title-input': _vm.title,
        'tw-pr-6 tw-truncate': _vm.isDropdown,
        error: _vm.errorFormat,
      },
    ],attrs:{"disabled":_vm.disabled,"maxlength":_vm.maxLength,"placeholder":_vm.placeholder,"required":_vm.required,"type":_vm.type},domProps:{"value":_vm.value},on:{"blur":_vm.onBlurEvent,"click":_vm.onClickEvent,"focus":_vm.onFocusEvent,"input":function($event){return _vm.onUpdateEvent($event.target.value)},"paste":_vm.onPhonePaste,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onClickEnterEvent($event.target.value)}}}),(_vm.errorFormat)?_c('span',{staticClass:"zem-input__error"},[_vm._v(_vm._s(_vm.errorFormat))]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }