// import axios from 'axios'
//
// export default function $api {
//   axios.defaults.headers.post['Accept'] = 'application/json'
//   axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
//   axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'
//   axios.defaults.headers.post['Access-Control-Allow-Methods'] = 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
//   axios.defaults.headers.post['Access-Control-Allow-Credentials'] = true
//
//   return axios
// }

import axios from 'axios'
import {router} from '@/router'
import Vue from 'vue'
import {logger} from '@/assets/scripts/scripts'

const $api = axios.create({
  baseURL: process.env.VUE_APP_URL,
  timeout: 0,
  headers: {
    Accept: 'application/json',
  },
})

$api.defaults.headers.post['Content-Type'] = 'application/json'
$api.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
$api.defaults.headers.post['Access-Control-Allow-Origin'] = '*'
$api.defaults.headers.post['Access-Control-Allow-Methods'] = 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
$api.defaults.headers.post['Access-Control-Allow-Credentials'] = true

export default $api

$api.interceptors.response.use(
  response => {
    return response
  },
  error => {
    // const currentUrl = router.history.current.path
    // if (error.response.status === 401 && currentUrl !== '/login') router.push('/login')
    handlerErrors(error)
    return Promise.reject(error)
  }
)

export const auth = () => {
  let accessToken = localStorage.getItem('accessToken')
  return accessToken ? {Authorization: 'Bearer ' + accessToken} : {}
}

export const handlerErrors = ({response, request, message}) => {
  const currentUrl = router.history.current.path
  const toastMessage = text => {
    logger(text, 'error')
		Vue.$toast.error(text, {
      timeout: 10000,
      closeButton: true,
      progressBar: true,
    })
  }

  if (response) {
    switch (response.status) {
      // case 0:
      //   toastMessage('Нет доступа к интернету. Проверьте сеть.')
      //   break
      case 401:
        if (currentUrl === '/login') toastMessage('Данные пользователя не найдены (401).')
        break
      case 404:
        logger('Запрашиваемая страница не найдена (404).')
        break
      case 500:
        toastMessage('Внутренняя Ошибка Сервера (500).')
        break
      default:
        const isMessage = Object.hasOwn(response.data, 'message') && response.data.message
        const isErrors = Object.hasOwn(response.data, 'errors') && response.data.errors
        const text = []

        if (currentUrl !== '/login') if (isMessage) text.push(`Message: ${response.data.message}`)
        if (isErrors) text.push(`Errors: ${JSON.stringify(response.data.errors)}`)

        if (!isMessage && !isErrors) {
          toastMessage(`Возникла ошибка, обратитесь к администратору. Код ошибки: ${response.status}`)
        } else {
          toastMessage(text.join('\n'))
        }

        logger(request.responseText, 'error')
    }
  } else if (request) {
    logger(request, 'error')
  } else {
    // Произошло что-то при настройке запроса, вызвавшее ошибку
    logger(message, 'error')
  }
}
