<template>
  <div :class="sidebarVisibilityClass" class="sidebar-right">
    <Arrow
      :class="arrowClass"
      :isInverted="$store.state.sidebars[sidebarName]"
      :sidebar-name="sidebarName"
      class="sidebar-right__arrow"
    />
    <slot />
  </div>
</template>

<script>
import Arrow from './ui/Arrow'

export default {
  name: 'SidebarRight',

  props: {
    isWide: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 500,
    },
    sidebarName: String,
  },

  components: {
    Arrow,
  },

  data() {
    return {
      isSideBarVisible: false,
    }
  },

  computed: {
    sidebarVisibilityClass() {
      if (this.isWide) {
        return !this.$store.state.sidebars[this.sidebarName] ? 'sidebar-right-wide--hide' : 'sidebar-right-wide--show'
      }
      return !this.$store.state.sidebars[this.sidebarName] ? 'sidebar-right--hide' : 'sidebar-right--show'
    },
    arrowClass() {
      return !this.$store.state.sidebars[this.sidebarName] ? 'sidebar-right__arrow--hide' : 'sidebar-right__arrow'
    },
  },

  methods: {
    collapseSidebar() {
      let newStr = this.sidebarName[0].toUpperCase() + this.sidebarName.slice(1)
      this.$store.commit(`sidebars/change${newStr}`, !this.$store.state.sidebars[this.sidebarName])
      // this.slideEvent();
    },
    slideEvent() {
      this.$emit('slide', this.$store.state.sidebars[this.sidebarName])
    },
  },
}
</script>

<style lang="scss" scoped>
.sidebar-right {
  height: 100vh;
  display: block;
  position: relative;
  //transition: all 0.5s;

  &--hide {
    width: 0;
    overflow: hidden;
  }

  &--show {
    min-width: 500px;
    width: 500px;
    //overflow: hidden;
  }

  &__arrow {
    position: absolute;
    left: -16px;
    top: calc(50vh - 16px);
    cursor: pointer;
    z-index: 10;

    &--hide {
      left: -32px;
    }
  }
}

@media screen and (max-width: 1440px) {
  .sidebar-right {
    &--show {
      width: 420px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .sidebar-right {
    height: 100%;
    position: fixed;
    top: 0;
    z-index: 5;
    margin-top: 45px;
    background: $color-white;
    display: flex;
    width: 100%;

    &--hide {
      right: -100%;
    }

    &--show {
      height: 100%;
      right: 0;
      min-width: unset;
    }

    &__arrow {
      display: none;
      left: 4px;

      &--hide {
        display: none;
      }
    }
  }
}
</style>
