<template>
  <label class="zem-checkbox">
    <slot />
    <input
      v-model="model"
      :checked="model"
      :disabled="disabled"
      :required="required"
      :value="inputValue"
      type="checkbox"
    />
    <span class="checkmark" :class="{ disabled: disabled}"></span>
  </label>
</template>

<script>
export default {
  name: 'ZemCheckbox',
  props: {
    value: {
      type: [String, Boolean, Array],
      default: false,
    },
    inputValue: {
      type: [String, Boolean],
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.onUpdateEvent(value)
      },
    },
  },
  methods: {
    onUpdateEvent(value) {
      this.$emit('input', value)
    },
  },
}
</script>

<style lang="scss" scoped>
.zem-checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 20px;
  height: 15px;
  cursor: pointer;
  font-weight: 400;
  font-size: 12px;
  line-height: 13px;
  color: $color-mine-shaft;
  text-align: left;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.zem-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 14px;
  width: 14px;
  background-color: $color-white;
  border: 1px solid $color-alto-gray;
  border-radius: 2px;
}

.zem-checkbox:hover input ~ .checkmark {
  background-color: $color-white;
}

.zem-checkbox input:checked ~ .checkmark {
  background-color: $color-accent;
  border: 1px solid $color-accent;
}

.zem-checkbox input:checked ~ .checkmark.disabled {
  background-color: #99cece;
  border: 1px solid #99cece;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.zem-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.zem-checkbox .checkmark:after {
  left: 5px;
  top: 2px;
  width: 3px;
  height: 7px;
  border: solid $color-white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
</style>
