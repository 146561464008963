import Vue from 'vue'
import Router from 'vue-router'

import store from '@/store'
import UserService from '@/services/user.service'
import AuthService from '@/services/auth.service'
import TokenService from '@/services/token.service'
import handlerRoles from "@/helpers/handlerRoles";
import moment from "moment";

Vue.use(Router)

export const router = new Router({
  mode: 'history',
  base: __dirname,
  routes: [
    {
      path: '/',
      name: 'redirect',
      component: () => import('@/views/Redirect.vue'),
      meta: {
        access: '',
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Authorization/Login.vue'),
      meta: {
        layout: 'full',
        access: '',
      },
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/Authorization/Register.vue'),
      meta: {
        layout: 'full',
        access: '',
      },
    },
    {
      path: '/password-reset',
      name: 'password-reset',
      component: () => import('@/views/Authorization/ResetPassword.vue'),
      meta: {
        layout: 'full',
        access: '',
      },
    },
    {
      path: '/password-reset/sent',
      name: 'password-reset-sent',
      component: () => import('@/views/Authorization/ResetPasswordSent.vue'),
      meta: {
        layout: 'full',
        access: '',
      },
    },
    {
      path: '/password-reset/error',
      name: 'password-reset-error',
      component: () => import('@/views/Authorization/ResetPasswordError.vue'),
      meta: {
        layout: 'full',
        access: '',
      },
    },
    {
      path: '/form-new-lead',
      name: 'form-new-lead',
      component: () => import('@/views/FormNewLead.vue'),
      meta: {
        layout: 'full',
        access: '',
      },
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('@/components/About.vue'),
    },
    {
      path: '/home',
      name: 'home',
      component: () => import('@/components/Home.vue'),
      redirect: to => {
        return {path: '/products', query: {q: to.params.searchText}}
      },
    },
    {
      path: '/counterparties/peoples',
      name: 'peoples',
      component: () => import('@/views/PeopleAndCompanies.vue'),
      meta: {
        access: '',
      },
    },
    {
      path: '/counterparties/peoples/:id',
      name: 'peoples-id',
      component: () => import('@/views/PeopleAndCompanies.vue'),
      meta: {
        access: '',
      },
    },
    {
      path: '/counterparties/organizations',
      name: 'organizations',
      component: () => import('@/views/PeopleAndCompanies.vue'),
      meta: {
        access: '',
      },
    },
    {
      path: '/counterparties/organizations/:id',
      name: 'organizations-id',
      component: () => import('@/views/PeopleAndCompanies.vue'),
      meta: {
        access: '',
      },
    },
    {
      path: '/products',
      name: 'products',
      component: () => import('@/views/Products.vue'),
      meta: {
        access: 'directory-item-read',
      },
    },
    {
      path: '/products/:id',
      name: 'products-id',
      component: () => import('@/views/Products.vue'),
      meta: {
        access: 'directory-item-read',
      },
    },
    {
      path: '/partner-orders',
      name: 'exchange',
      component: () => import('@/views/Exchange.vue'),
      meta: {
        access: 'partner-order-read',
      },
    },
    {
      path: '/partner-orders/:id',
      name: 'exchange-id',
      component: () => import('@/views/Exchange.vue'),
      meta: {
        access: 'partner-order-read',
      },
    },
    {
      path: '/quick-calculation',
      name: 'quick-calculation',
      component: () => import('@/views/QuickCalculation.vue'),
      meta: {
        access: '',
      },
    },
    {
      path: '/estimates',
      name: 'estimates',
      component: () => import('@/views/Estimates.vue'),
      meta: {
        access: 'estimate-read',
      },
    },
    {
      path: '/users',
      name: 'users',
      component: () => import('@/views/Users.vue'),
      meta: {
        access: 'user-read',
      },
    },
    {
      path: '/users/:id',
      name: 'users-id',
      component: () => import('@/views/Users.vue'),
      meta: {
        access: 'user-read',
      },
    },
    {
      path: '/orders',
      name: 'orders',
      component: () => import('@/views/Orders.vue'),
      meta: {
        access: '',
      },
      children: [
        {
          path: '/orders/settings/:id',
          name: 'orders-settings-id',
          component: () => import('@/views/Orders.vue'),
          meta: {
            access: 'partner-order-read',
          },
        },
      ],
    },
    {
      path: '/orders/:id',
      name: 'orders-id',
      component: () => import('@/views/Orders.vue'),
      meta: {
        access: 'partner-order-read',
      },
    },
    {
      path: '/directory',
      name: 'directory',
      component: () => import('@/views/Directory.vue'),
      meta: {
        access: '',
      },
    },
    {
      path: '/directory/:type',
      name: 'directory-type',
      component: () => import('@/views/Directory.vue'),
      meta: {
        access: '',
      },
    },
    {
      path: '/directory/:type/:id',
      name: 'directory-id',
      component: () => import('@/views/Directory.vue'),
      meta: {
        access: '',
      },
    },
    {
      path: '/roles',
      name: 'roles',
      component: () => import('@/views/Roles.vue'),
      meta: {
        access: 'role-read',
      },
    },
    {
      path: '/roles/:id',
      name: 'roles-id',
      component: () => import('@/views/Roles.vue'),
      meta: {
        access: 'role-read',
      },
    },
    {
      path: '/objects',
      name: 'objects',
      component: () => import('@/views/Objects.vue'),
      meta: {
        access: 'base-object-read',
      },
    },
    {
      path: '/objects/:id',
      name: 'objects-id',
      component: () => import('@/views/Objects.vue'),
      meta: {
        access: 'base-object-read',
      },
    },
    {
      path: '/history',
      name: 'history',
      component: () => import('@/views/History.vue'),
      meta: {
        access: '',
      },
    },
    {
      path: '/history/:id',
      name: 'history-id',
      component: () => import('@/views/History.vue'),
      meta: {
        access: '',
      },
    },
    {
      path: '/logs',
      name: 'logs',
      component: () => import('@/views/Logs.vue'),
      meta: {
        access: 'vendor-response-log-read',
      },
    },
    {
      path: '/logs/:id',
      name: 'logs-id',
      component: () => import('@/views/Logs.vue'),
      meta: {
        access: 'vendor-response-log-read',
      },
    },
    {
      path: '/events',
      name: 'events',
      component: () => import('@/views/Events.vue'),
      meta: {
        access: '',
      },
    },
    {
      path: '/events/:id',
      name: 'events-id',
      component: () => import('@/views/Events.vue'),
      meta: {
        access: '',
      },
    },
  ],
})

const refreshToken = nextStep => {
  AuthService.refresh(TokenService.getLocalRefreshToken())
    .then(async r => {
      await TokenService.updateLocalAccessToken(r.data['access_token'])
      await TokenService.updateLocalRefreshToken(r.data['refresh_token'])
      await nextStep()
      router.go(0)
    })
    .catch(e => {
      if ([400, 401, 429].includes(e.response.status)) {
        TokenService.deleteLocalAccessToken()
        TokenService.deleteLocalRefreshToken()
        router.push('/login').then(() => null)
      }
    })
}

const getProfile = (timestamp, nextStep) => {
  store.commit('other/CHANGE_ROUTING', true)
  UserService.getUserProfile()
    .then(r => {
      store.commit('user/profileSuccess', r.data.data)
      store.commit('other/SET_TIMESTAMP', timestamp)
      handlerRoles(r.data.data.roles.data)
      nextStep()
    })
    .catch(e => {
      if (e.response.status === 401)
        if (TokenService.getLocalRefreshToken()) refreshToken(nextStep)
    })
}

router.beforeEach(async (to, from, next) => {
  const timestamp = moment().startOf('D').unix()
  if (
    TokenService.getLocalAccessToken() &&
    store.state.other.timestamp !== timestamp &&
    TokenService.getLocalRefreshToken()
  ) {
    getProfile(timestamp, nextStep)
  } else {
    nextStep()
  }

  store.commit('profile/showProfile', false)

  function nextStep() {
    const publicPages = [
      '/login',
      '/register',
      '/password-reset',
      '/password-reset/sent',
      '/password-reset/error',
      '/form-new-lead',
    ]

    if (!publicPages.includes(to.path) && !TokenService.getLocalAccessToken() && !TokenService.getLocalRefreshToken()) {
      router.push('/login').then(() => null)
    } else {
      next()
    }

    if (!publicPages.includes(to.path)) {
      store.commit('other/changeDesktopMenu', true)
    } else {
      store.commit('other/changeDesktopMenu', true)
    }
  }
})

router.afterEach(() => {
  store.commit('other/CHANGE_ROUTING', false)
})
