import Vue from 'vue'
import moment from 'moment'
import store from '@/store'

const _ = require('lodash')

export const logger = (ctx, type) => {
  if (type === 'error') {
    console.log(
      `%cERROR%c ${new Date().toUTCString().slice(17, 25)}`,
      'padding: 2px 4px; background-color: #d86363; color: #FFFFFF; border-radius: 4px',
      'color: #d86363',
      ctx
    )
  } else if (type === 'warn') {
    console.log(
      `%cWARN%c ${new Date().toUTCString().slice(17, 25)}`,
      'padding: 2px 4px; background-color: #df9a4b; color: #FFFFFF; border-radius: 4px',
      'color: #df9a4b',
      ctx
    )
  } else if (type === 'help') {
    console.log(
      `%cHELP%c ${new Date().toUTCString().slice(17, 25)}`,
      'padding: 2px 4px; background-color: #329AF0; color: #FFFFFF; border-radius: 4px',
      'color: #329AF0',
      ctx
    )
  } else {
    console.log(
      `%cINFO%c ${new Date().toUTCString().slice(17, 25)}`,
      'padding: 2px 4px; background-color: #329AF0; color: #FFFFFF; border-radius: 4px',
      'color: #329AF0',
      ctx
    )
  }
}

export const phoneTrim = ctx => {
  return ctx.replace('8 ', '7').replace(' (', '').replace('(', '').replace(') ', '').replaceAll('-', '')
}

export const formattedPhone = phone => {
  if (phone) {
    if (phone.length > 0 && phone.length < 11) return phone
    let ph = !phone ? '' : String(phone)
    return ph.length > 0
      ? `+${ph[0]} (${ph[1]}${ph[2]}${ph[3]}) ${ph[4]}${ph[5]}${ph[6]}-${ph[7]}${ph[8]}-${ph[9]}${ph[10]}`
      : ''
  } else {
    return ''
  }
}

export const errorHandler = data => {
  const request = JSON.parse(data.request.responseText || [])

  if (data.request.status === 400) {
    Vue.$toast.error(request.message)
  } else if (data.request.status === 417) {
    Vue.$toast.error(request.message)
  } else if (data.request.status === 422) {
    const errors = request.errors,
      keys = Object.keys(errors),
      newArr = []
    if (keys.length > 0) {
      keys.forEach((item, i) => {
        newArr.push({
          title: item,
          text: errors[keys[i]][0],
        })
        Vue.$toast.error(errors[keys[i]][0])
      })
    } else {
      Vue.$toast.error(request.message)
    }
    return newArr
  } else {
    return []
  }
}

export const formattedDate = date => {
  return moment.unix(date).format('DD.MM.YYYY')
}

export const formattedDateTime = date => {
  return moment.unix(date).format('DD.MM.YYYY HH:mm:ss')
}

export const getAllIds = a => {
  let id_list = []

  const get_ids = a => {
    for (let i in a) {
      id_list.push(a[i]['id'])
      if (a[i]['children'].length > 0) {
        id_list = get_ids(a[i]['children'], id_list)
      }
    }

    return id_list
  }

  get_ids(a)

  return id_list
}

export const getAllIdsTree = a => {
  let id_arr = _.cloneDeep(a)

  const get_ids = a => {
    for (let i in a) {
      a[i] = {
        id: a[i].id,
        children: a[i].children,
      }
      if (a[i]['children'].length > 0) {
        get_ids(a[i]['children'])
      }
    }

    return id_arr
  }

  get_ids(id_arr)

  return id_arr
}

export const closeAcc = numbers => {
  const publicPages = [
    '/login',
    '/register',
    '/password-reset',
    '/password-reset/sent',
    '/password-reset/error',
    '/form-new-lead',
  ]

  if (publicPages.includes(window.location.pathname)) return true

  const access = {
    all: 1,
    supplier: 2,
    admin: 3,
    user: 4,
    'front-manager': 5,
    partner: 6,
    estimator: 7,
  }

  if ([undefined, 1].includes(access[store.state.user.role])) return false

  return !numbers.includes(access[store.state.user.role])
}
