export const events = {
  namespaced: true,

  state: {
    isGetAllItemsSuccess: false,
    deleteAllItemsSuccess: false,
    elements: [],
    selectedElements: [],
    logsPagination: {
      custom: {
        total: 0,
      },
      pagination: {
        current_page: 1,
        total_pages: 1,
        total: 0,
      },
    },
    selectedItemsForDelete: [],
    paginationPage: 1,
    filter: {},
    orderBy: null,
    sortedBy: null,
    search: '',
    searchCount: 0,
    statusLogs: false,
    loading: {
      takeInWork: false,
      takeLogsInWork: false,
      takeAllLogsInWork: false,
    },
    isArchive: false,
  },

  mutations: {
    getAllElements(state, logs) {
      state.isGetAllItemsSuccess = true
      state.elements = logs.data
      if (logs.meta) state.logsPagination = logs.meta
    },
    getSelectedLogs(state, logs) {
      state.selectedElements = logs.data
    },
    getAllLogsFailure(state) {
      state.isGetAllItemsSuccess = false
      state.elements = null
      state.logsPagination = {
        custom: {
          total: 0,
          settings: {
            value: {},
          },
        },
        pagination: {
          current_page: 1,
          total_pages: 1,
          total: 0,
        },
      }
    },
    changePagination(state) {
      state.logsPagination = state
    },
    deleteAllLogs(state, data) {
      state.deleteAllItemsSuccess = data
    },
    itemsForDelete(state, data) {
      state.selectedItemsForDelete = data
    },
    getAddress(state, data) {
      state.address = data.data.suggestions
    },
    getStatuses(state, data) {
      state.statuses = data
    },
    getPartnerStatuses(state, data) {
      state.partnerStatuses = data
    },
    filterMain(state, data) {
      state.filter['main'] = data
    },
    changePage(state, data) {
      state.paginationPage = data
    },
    sorting(state, data) {
      state.sortedBy = data
    },
    ordering(state, data) {
      state.orderBy = data
    },
    getSearch(state, data) {
      state.search = data
    },
    getSearchCount(state, data) {
      state.searchCount = data
    },
    updateStatusLogs(state, data) {
      state.statusLogs = data
    },
    changeIsArchive(state, data) {
      state.isArchive = data
    },
    changeCurrentPage(state) {
      state.paginationPage = 1
    },
    changeTakeInWorkPreloader(state, data) {
      state.loading.takeInWork = data
    },
    changeTakeLogsInWorkPreloader(state, data) {
      state.loading.takeLogsInWork = data
    },
    changeTakeAllLogsInWorkPreloader(state, data) {
      state.loading.takeAllLogsInWork = data
    },
    clearState(state) {
      state = {
        isGetAllItemsSuccess: false,
        deleteAllLogsSuccess: false,
        elements: [],
        selectedElements: [],
        logsPagination: {
          custom: {
            total: 0,
            settings: {
              value: {},
            },
          },
          pagination: {
            current_page: 1,
            total_pages: 1,
            total: 0,
          },
        },
        statuses: [],
        partnerStatuses: [],
        selectedItemsForDelete: [],
        paginationPage: 1,
        filter: {},
        orderBy: null,
        sortedBy: null,
        search: '',
        searchCount: 0,
        statusLogs: false,
      }
    },
  },

  actions: {},
}
